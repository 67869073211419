import React from 'react';

function AboutPage() {
  return (
    <main className="main-container">
      <h2>About Me</h2>
      <p>I am a passionate web developer...</p>
    </main>
  );
}

export default AboutPage;