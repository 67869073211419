import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../images/DTSLogo.svg'; // Update this path to where you moved the SVG
import './Header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <header className="header">
      <nav className="container-fluid">
        {/* Logo on the left */}
        <Link to="/" className="text-white text-decoration-none">
          <Logo className="logo" />
        </Link>

        {/* Hamburger Menu */}
        <div className="hamburger" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>

        {/* Navigation links, centered */}
        <ul className={`nav-links ${menuOpen ? 'active' : ''}`}>
          <li><Link to="/">See Our</Link></li>
          <li><Link to="/">About Us</Link></li>
          <li><Link to="/">Contact Us</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
