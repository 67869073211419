import React from 'react';
import './ToDoPage.css'; // Import the CSS file
import ToDoDataTable from '../components/ToDoDataTable'

function ToDoPage() {
  return (
    <main className="main-container">
      <div className="content1">
            <div className="ToDoGrid">
                <ToDoDataTable />
            </div>
      </div>
    </main>
  );
}

export default ToDoPage;