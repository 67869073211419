import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ToDoPage from './pages/ToDoPage';
import Footer from './components/Footer';
import Header from './components/Header';
import './App.css'; // Import the App CSS

const App = () => {
  return (
    <div className="app-container">
      <Router>
        {/* Only show the header on the homepage or other routes you specify */}
        <main className="main-content">
          <header>
            <Header />
          </header>
          <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/AboutPage" element={<AboutPage />} />
              <Route path="/ToDoPage" element={<ToDoPage />} />
            
          </Routes>
        </main>
        <footer>
          <Footer />
        </footer>
          
      </Router>
    </div>
  );
};

export default App;
