import React from 'react';
import './Footer.css';
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footerContent">
        <p>&copy; 2024 My Portfolio</p>
        <p>
          <a href="/privacy-policy" className="text-white text-decoration-none">Privacy Policy</a> | 
          <a href="/terms" className="text-white text-decoration-none"> Terms of Service</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
