import React, { useEffect, useRef } from 'react';
import './HomePage.css'; // Import the CSS file
import VideoFile1 from '../images/DucommunTechLandingPageVideo2.mp4';
import VideoFile2 from '../images/DTSVideo2.mp4';

const HomePage = () => {
  const content2Ref = useRef(null); // Create a ref for .content2

  useEffect(() => {
    // Store the ref in a variable to avoid the warning
    const content2Element = content2Ref.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // When .content2 comes into view, add the class to trigger the fade-in
            entry.target.classList.add('fadeInVisible');
          }
        });
      },
      {
        threshold: 0.5, // Trigger when 50% of the element is visible
      }
    );

    // Start observing the .content2 element
    if (content2Element) {
      observer.observe(content2Element);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (content2Element) {
        observer.unobserve(content2Element);
      }
    };
  }, []); // Empty dependency array to run the effect only once

  return (
    <main className="main-container">
      <div className="video-background">
        <video preload="auto" autoPlay loop muted playsInline loading="lazy" >
          <source src={VideoFile1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="content">
          <h2>DUCOMMUNTECH STUDIOS LLC</h2>
          <h1>Code with Purpose. Create with Passion.</h1>
          <p>At DucommunTech Solutions, I bring a fresh perspective and a passion for technology to every project!</p>
          <h2>To view my creations please click on the interactive examples below:</h2>
          <a href="http://www.rstoneconstructs.com" target="_blank" rel="noopener noreferrer">
            <button type="button">RavenStone Construction Website</button>
          </a>
        </div>
      </div>

      <div className="video-background">
        <video preload="auto" autoPlay loop muted playsInline loading="lazy">
          <source src={VideoFile2} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          ref={content2Ref} // Attach the ref to .content2
          className="content2"
        >
          <h1>Are you curious on how this website was built?</h1>
          <p>Click view more to see how this website was put together!</p>
          
          <button type="button">View More</button>
          
        </div>
      </div>
    </main>
  );
};

export default HomePage;
